import React from "react";

function Application() {
    return (
        <div>
            <h2>应用页面</h2>
            <p>This is the about page.</p>
        </div>
    );
}

export default Application;