// Component Name: Classified
import { useState, useEffect } from 'react'
import Nav from './Nav'
import Footer from './Footer'
import ImgTheme1 from '../static/classified-themes-image-1.png'
import ImgTheme2 from '../static/classified-themes-image-2.png'
import '../css/Classified.scss'

function Classified() {
    useEffect(() => {
        document.title = '分类 - 7G'
        document.querySelector('#root').className = 'classified'
    }, [])

    return (
        <>
            <header className='header'>
                <div className='container'>
                    <Nav />
                    <div className='header__hero'>
                        <div className='header__hero--intro invisible'>
                            <span className='time'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='18'
                                    viewBox='0 0 16 18'
                                    fill='none'
                                >
                                    <path
                                        d='M5.14892 10.2451L7.37977 8.49206V4.92103C7.37977 4.56196 7.65677 4.27173 7.99945 4.27173C8.34214 4.27173 8.61914 4.56196 8.61914 4.92103V8.81674C8.61914 9.02126 8.52742 9.21411 8.37127 9.33618L5.89258 11.284C5.78105 11.3717 5.65089 11.4139 5.52139 11.4139C5.33239 11.4139 5.14648 11.3249 5.02502 11.1535C4.81923 10.8672 4.87502 10.4601 5.14892 10.2451Z'
                                        fill='white'
                                    />
                                    <path
                                        d='M8 0.38208C12.4115 0.38208 16 4.14208 16 8.76433C16 13.3866 12.4115 17.1466 8 17.1466C3.58853 17.1466 0 13.3866 0 8.76433C0 4.14208 3.58853 0.38208 8 0.38208ZM8 15.848C11.7273 15.848 14.7607 12.6698 14.7607 8.76433C14.7607 4.85889 11.7273 1.68064 8 1.68064C4.27203 1.68064 1.23934 4.85889 1.23934 8.76433C1.23934 12.6698 4.27266 15.848 8 15.848Z'
                                        fill='white'
                                    />
                                </svg>
                                120 min
                            </span>
                            <span className='subtitle'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='18'
                                    viewBox='0 0 20 18'
                                    fill='none'
                                >
                                    <path
                                        d='M17.0706 2.44081C16.8418 2.2362 16.4708 2.2362 16.2419 2.44081C16.0131 2.64542 16.0131 2.97723 16.2419 3.18188C19.684 6.2602 19.684 11.2691 16.2419 14.3475C16.0131 14.5521 16.0131 14.8839 16.2419 15.0885C16.3563 15.1908 16.5063 15.242 16.6562 15.242C16.8062 15.242 16.9562 15.1908 17.0705 15.0885C18.9593 13.3994 19.9995 11.1535 19.9995 8.76465C19.9995 6.3758 18.9593 4.12996 17.0706 2.44081Z'
                                        fill='white'
                                    />
                                    <path
                                        d='M15.2952 4.02821C15.0664 3.8236 14.6954 3.8236 14.4665 4.02821C14.2377 4.23286 14.2377 4.56463 14.4665 4.76928C15.6599 5.83646 16.3171 7.25532 16.3171 8.76452C16.3171 10.2737 15.6599 11.6926 14.4665 12.7597C14.2378 12.9643 14.2377 13.2961 14.4665 13.5008C14.581 13.6031 14.7309 13.6543 14.8808 13.6543C15.0308 13.6543 15.1808 13.6031 15.2951 13.5008C16.7098 12.2357 17.4889 10.5537 17.4889 8.76452C17.4889 6.97536 16.7098 5.29333 15.2952 4.02821Z'
                                        fill='white'
                                    />
                                    <path
                                        d='M12.2644 0.517606C11.8241 0.299615 11.2651 0.346253 10.7304 0.645571L4.18324 4.31131H2.15492C0.96668 4.31131 0 5.17584 0 6.23851V11.2901C0 12.3528 0.96668 13.2173 2.15492 13.2173H4.18324L10.7303 16.8831C11.0427 17.058 11.3634 17.1466 11.6639 17.1466C11.8777 17.1466 12.0813 17.1017 12.2644 17.011C12.7046 16.793 12.9571 16.3445 12.9571 15.7803V1.74828C12.9571 1.18419 12.7046 0.735632 12.2644 0.517606ZM3.76559 12.1693H2.15492C1.61285 12.1693 1.17187 11.7749 1.17187 11.2901V6.23851C1.17187 5.75372 1.61285 5.35935 2.15492 5.35935H3.76559V12.1693ZM11.7852 15.7803C11.7852 16.0023 11.7143 16.0855 11.6967 16.0941C11.6791 16.1028 11.5627 16.1126 11.3522 15.9947L4.93746 12.4031V5.12553L11.3522 1.53385C11.5627 1.41598 11.6791 1.4258 11.6967 1.43443C11.7142 1.44313 11.7852 1.5263 11.7852 1.74824V15.7803Z'
                                        fill='white'
                                    />
                                </svg>
                                Subtitles
                            </span>
                            <span className='ratings'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='16'
                                    viewBox='0 0 15 16'
                                    fill='none'
                                >
                                    <path
                                        d='M14.8406 6.48542L11.5537 9.77367L12.3299 14.418C12.3637 14.621 12.2824 14.8263 12.1199 14.9476C12.0281 15.0164 11.9188 15.051 11.8096 15.051C11.7257 15.051 11.6413 15.0304 11.5642 14.9887L7.5 12.796L3.4363 14.9882C3.259 15.0846 3.04318 15.0689 2.88065 14.947C2.71813 14.8257 2.63686 14.6205 2.67063 14.4174L3.44686 9.77313L0.159386 6.48542C0.0158556 6.34137 -0.0363851 6.12583 0.0258816 5.93034C0.0881483 5.73484 0.253314 5.59133 0.45225 5.56154L4.99508 4.88461L7.02667 0.659486C7.2045 0.289611 7.7955 0.289611 7.97333 0.659486L10.0049 4.88461L14.5477 5.56154C14.7467 5.59133 14.9119 5.7343 14.9741 5.93034C15.0364 6.12638 14.9841 6.34083 14.8406 6.48542Z'
                                        fill='#E5084A'
                                    />
                                </svg>
                                9.3
                            </span>
                            <span className='year'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='18'
                                    viewBox='0 0 16 18'
                                    fill='none'
                                >
                                    <path
                                        d='M2.20308 17.1466H13.7969C15.0123 17.1466 16 16.15 16 14.9237V3.84673C16 2.62044 15.0123 1.62387 13.7969 1.62387H12.9231V1.00298C12.9231 0.661473 12.6461 0.38208 12.3077 0.38208C11.9692 0.38208 11.6923 0.661473 11.6923 1.00298V1.62387H4.30771V1.00298C4.30771 0.661473 4.03075 0.38208 3.69229 0.38208C3.35382 0.38208 3.07692 0.661473 3.07692 1.00298V1.62387H2.20308C0.9877 1.62387 0 2.62044 0 3.84673V14.9237C0 16.15 0.9877 17.1466 2.20308 17.1466ZM1.2308 3.84673C1.2308 3.30655 1.66771 2.86572 2.20308 2.86572H3.07692V3.48661C3.07692 3.82812 3.35382 4.10751 3.69229 4.10751C4.03075 4.10751 4.30766 3.82812 4.30766 3.48661V2.86572H11.6923V3.48661C11.6923 3.82812 11.9692 4.10751 12.3077 4.10751C12.6461 4.10751 12.923 3.82812 12.923 3.48661V2.86572H13.7969C14.3322 2.86572 14.7692 3.30655 14.7692 3.84673V5.6598H1.2308V3.84673ZM1.2308 6.90159H14.7693V14.9237C14.7693 15.4639 14.3323 15.9047 13.797 15.9047H2.20308C1.66771 15.9047 1.2308 15.4639 1.2308 14.9237V6.90159Z'
                                        fill='white'
                                    />
                                </svg>
                                2023
                            </span>
                        </div>
                        <h1 className='header__hero--title'>
                            商业领袖的
                            <br />
                            圣经智慧
                        </h1>
                        <div className='header__hero--tags invisible'>
                            <span className='tag'>职业</span>
                            <span className='tag'>鲍勃的故事</span>
                        </div>
                        <div className='header__hero--cta'>
                            <button className='btn btn--primary'>立即播放</button>
                            <button className='btn btn--secondary'>加入收藏</button>
                        </div>
                        <div className='header__hero--slides'>
                            <a href='#' className='slides__prev'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='26'
                                    viewBox='0 0 25 26'
                                    fill='none'
                                >
                                    <path
                                        d='M12.1018 3.88403C17.1146 3.88403 21.1783 8.106 21.1783 13.3141C21.1783 18.5221 17.1146 22.7441 12.1018 22.7441C7.08905 22.7441 3.02539 18.5221 3.02539 13.3141C3.02539 8.106 7.08905 3.88403 12.1018 3.88403ZM13.0797 8.82922L8.76305 13.3141L13.0797 17.7989L14.1494 16.6876L10.903 13.3141L14.1494 9.94056L13.0797 8.82922Z'
                                        fill='white'
                                    />
                                </svg>
                            </a>
                            <a href='#' className='slides__dot'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                >
                                    <ellipse
                                        cx='4.87807'
                                        cy='4.79021'
                                        rx='4.53822'
                                        ry='4.71502'
                                        fill='white'
                                        fillOpacity='0.1'
                                    />
                                </svg>
                            </a>
                            <a href='#' className='slides__dot'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                >
                                    <ellipse
                                        cx='4.87807'
                                        cy='4.79021'
                                        rx='4.53822'
                                        ry='4.71502'
                                        fill='white'
                                        fillOpacity='0.1'
                                    />
                                </svg>
                            </a>
                            <a href='#' className='slides__dot'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='10'
                                    height='10'
                                    viewBox='0 0 10 10'
                                    fill='none'
                                >
                                    <ellipse
                                        cx='4.87807'
                                        cy='4.79021'
                                        rx='4.53822'
                                        ry='4.71502'
                                        fill='white'
                                        fillOpacity='0.1'
                                    />
                                </svg>
                            </a>
                            <a href='#' className='slides__dot'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='11'
                                    height='10'
                                    viewBox='0 0 11 10'
                                    fill='none'
                                >
                                    <ellipse
                                        cx='5.51478'
                                        cy='4.79021'
                                        rx='4.53822'
                                        ry='4.71502'
                                        fill='white'
                                    />
                                </svg>
                            </a>
                            <a href='#' className='slides__next'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='26'
                                    viewBox='0 0 25 26'
                                    fill='none'
                                >
                                    <path
                                        d='M12.2917 3.88403C7.27894 3.88403 3.21527 8.106 3.21527 13.3141C3.21527 18.5221 7.27894 22.7441 12.2917 22.7441C17.3045 22.7441 21.3682 18.5221 21.3682 13.3141C21.3682 8.106 17.3045 3.88403 12.2917 3.88403ZM11.3138 8.82922L15.6305 13.3141L11.3138 17.7989L10.2441 16.6876L13.4906 13.3141L10.2441 9.94056L11.3138 8.82922Z'
                                        fill='white'
                                        fillOpacity='0.18'
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <section className='content'>
                <div className="container">
                    <div className='title'>
                        <h1 className='title__title'>主题视频</h1>
                        <ul className='title__tags'>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 爱情 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 人文 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 超自然 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 科学和信仰 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 医治 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 心理 </a>{' '} </li>
                            <li className='title__tags--item'> {' '} <a href='#' className='title__tags--link'> 亲子 </a>{' '} </li>
                        </ul>
                        <div className='content__title--status'>
                            状态：
                            <span className='status'> <a href="#" className="status__link">全部</a></span>
                            <span className='status'> <a href="#" className="status__link">已完结</a></span>
                            <span className='status'> <a href="#" className="status__link">更新中</a></span>
                        </div>
                    </div>
                    <div className='content__cards'>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme1} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme2} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme1} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme2} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme1} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme2} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme1} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme2} alt='Card Image' />
                            </a>
                        </div>
                        <div className='card'>
                            <div className='card__content'>
                                <h1> 职业 </h1>
                                <div className='card__content--info'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='68'
                                            height='14'
                                            viewBox='0 0 68 14'
                                            fill='none'
                                        >
                                            <path
                                                d='M7.48516 0.587639L9.06298 3.75804C9.1624 3.95679 9.35321 4.09511 9.57609 4.12691L13.1037 4.6357C13.6618 4.71679 13.8846 5.3973 13.4806 5.78684L10.9278 8.25448C10.7675 8.4103 10.6937 8.63289 10.7322 8.85231L11.3351 12.3359C11.4297 12.8877 10.846 13.3074 10.3474 13.0482L7.19172 11.4026C6.99289 11.2993 6.75558 11.2993 6.55835 11.4026L3.4011 13.0482C2.90082 13.309 2.31715 12.8877 2.41336 12.3359L3.01627 8.85231C3.05475 8.63289 2.98099 8.4103 2.82064 8.25448L0.267909 5.78684C-0.136167 5.39571 0.0867166 4.7152 0.644727 4.6357L4.17238 4.12691C4.39366 4.09511 4.58607 3.95679 4.68549 3.75804L6.26331 0.587639C6.51345 0.0852077 7.23502 0.0852077 7.48516 0.587639Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M28.698 0.587272L30.2759 3.75767C30.3753 3.95642 30.5661 4.09475 30.789 4.12655L34.3166 4.63534C34.8746 4.71643 35.0975 5.39693 34.6934 5.78648L32.1407 8.25411C31.9804 8.40993 31.9066 8.63253 31.9451 8.85194L32.548 12.3356C32.6426 12.8873 32.0589 13.307 31.5603 13.0479L28.4046 11.4023C28.2058 11.2989 27.9685 11.2989 27.7712 11.4023L24.614 13.0479C24.1137 13.3086 23.53 12.8873 23.6263 12.3356L24.2292 8.85194C24.2676 8.63253 24.1939 8.40993 24.0335 8.25411L21.4808 5.78648C21.0767 5.39534 21.2996 4.71484 21.8576 4.63534L25.3853 4.12655C25.6065 4.09475 25.799 3.95642 25.8984 3.75767L27.4762 0.587272C27.7263 0.0848415 28.4479 0.0848415 28.698 0.587272Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M42.3816 0.587517L43.9595 3.75792C44.0589 3.95667 44.2497 4.09499 44.4726 4.12679L48.0002 4.63558C48.5582 4.71667 48.7811 5.39718 48.377 5.78672L45.8243 8.25436C45.664 8.41017 45.5902 8.63277 45.6287 8.85219L46.2316 12.3358C46.3262 12.8875 45.7425 13.3073 45.2438 13.0481L42.0882 11.4025C41.8894 11.2992 41.6521 11.2992 41.4548 11.4025L38.2976 13.0481C37.7973 13.3089 37.2136 12.8875 37.3098 12.3358L37.9128 8.85219C37.9512 8.63277 37.8775 8.41017 37.7171 8.25436L35.1644 5.78672C34.7603 5.39559 34.9832 4.71508 35.5412 4.63558L39.0689 4.12679C39.2901 4.09499 39.4826 3.95667 39.582 3.75792L41.1598 0.587517C41.4099 0.0850857 42.1315 0.0850857 42.3816 0.587517Z'
                                                fill='#FFB800'
                                            />
                                            <path
                                                d='M60.8553 0.587639L62.4331 3.75804C62.5325 3.95679 62.7233 4.09511 62.9462 4.12691L66.4739 4.6357C67.0319 4.71679 67.2548 5.3973 66.8507 5.78684L64.2979 8.25448C64.1376 8.4103 64.0638 8.63289 64.1023 8.85231L64.7052 12.3359C64.7998 12.8877 64.2162 13.3074 63.7175 13.0482L60.5618 11.4026C60.363 11.2993 60.1257 11.2993 59.9285 11.4026L56.7712 13.0482C56.2709 13.309 55.6873 12.8877 55.7835 12.3359L56.3864 8.85231C56.4249 8.63289 56.3511 8.4103 56.1908 8.25448L53.638 5.78684C53.234 5.39571 53.4568 4.7152 54.0148 4.6357L57.5425 4.12691C57.7638 4.09511 57.9562 3.95679 58.0556 3.75804L59.6334 0.587639C59.8836 0.0852077 60.6051 0.0852077 60.8553 0.587639Z'
                                                fill='#FFB800'
                                            />
                                        </svg>
                                        <p>345 Reviews</p>
                                    </span>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='48'
                                        height='49'
                                        viewBox='0 0 48 49'
                                        fill='none'
                                    >
                                        <rect
                                            x='0.105469'
                                            y='0.819092'
                                            width='47.8905'
                                            height='47.4871'
                                            rx='20'
                                            fill='#E4E4E4'
                                        />
                                        <path
                                            d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z'
                                            fill='#FF055F'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <a href="#" className='card__img'>
                                <img src={ImgTheme2} alt='Card Image' />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Classified
