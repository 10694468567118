// src/App.js
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Home from './Home';
import Classified from './Classified';
import Series from './Series';
import Wishes from './Wishes';
import Application from './Application';
import Onplay from './Onplay';
import SeriesOnplay from './SeriesOnplay';
// import HelloWorld from './HelloWorld';


// App component
function App() {
  
  const { id } = useParams(); 

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/classified" element={<Classified />} />
          <Route path="/series" element={<Series />} />
          <Route path="/wishes" element={<Wishes />} />
          <Route path="/application" element={<Application />} />
          <Route path="/onplay/:id" element={<Onplay />} />
          <Route path="/seriesonplay/:id" element={<SeriesOnplay />} />
          {/* <Route path="/hello-world" element={<HelloWorld />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
