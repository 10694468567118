import { useState, useEffect } from "react";
import "../css/Home.scss";
import Nav from "./Nav";
import Footer from "./Footer";
// import LoveIcon from "../static/love_icon.svg";
// import TimeIcon from "../static/time_icon.svg";
// import SubtitleIcon from "../static/subtitle_icon.svg";
// import RatingIcon from "../static/rating_icon.svg";
// import YearIcon from "../static/year_icon.svg";
// import ImgOnTheAir1 from "../static/on-the-air-image-1.png";
// import ImgOnTheAir2 from "../static/on-the-air-image-2.png";
import ImgSelected1 from "../static/selected_image_1.png";
import ImgSelected2 from "../static/selected_image_2.png";
import ImgRecommandations1 from "../static/recommandations-image-1.png";
import ImgRecommandations2 from "../static/recommandations-image-2.png";
import ImgSeries1 from "../static/series_image-1.png";
import ImgSeries2 from "../static/series_image-2.png";
import ImgSeries3 from "../static/series_image-3.png";
import btnPrev from "../static/btnPrevious.png";
import btnNext from "../static/btnNext.png";
import ImgEvangelize from "../static/evangelize-image.png";
import ImgEvangelize1 from "../static/evangelize-image-1.png";
import ImgEvangelize2 from "../static/evangelize-image-2.png";
import ImgEvangelize3 from "../static/evangelize-image-3.png";
import ImgEvangelize4 from "../static/evangelize-image-4.png";
import axios from "axios";

function Home() {

    const [seriesSlides, setSeriesSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);

    const [seriesCarrousel, setSeriesCarrousel] = useState([])
    const [currentCarrouselPage, setCurrentCarrouselPage] = useState(1);
    const itemsPerCarrouselPage = 5;
    const totalCarrouselPages = Math.ceil(seriesCarrousel.length / itemsPerCarrouselPage);

    const [selectedVideos, setSelectedVideos] = useState([]);
    const [currentSelectedVideoPage, setCurrentSelectedVideoPage] = useState(1);
    const videosPerPage = 4;
    const totalSelectedVideoPages = Math.ceil(selectedVideos.length / videosPerPage);

    const [recommendVideos, setRecommendVideos] = useState([]);
    const [currentRecommendVideoPage, setCurrentRecommendVideoPage] = useState(1);
    const recommendVideosPerPage = 10;
    const totalRecommendVideoPages = Math.ceil(recommendVideos.length / recommendVideosPerPage);

    const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState(1);
    const questionsPerPage = 7;

    useEffect(() => {
        document.title = "首页 - 7G";
        document.querySelector('#root').className = 'home'

        // 轮播图
        const fetchSeries = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/slides`);
                setSeriesSlides(response.data);
            } catch (error) {
                console.error('Error fetching series:', error);
            }
        };
        fetchSeries();

        // 精选视频
        const fetchSelectedVideos = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/selected`);
                setSelectedVideos(response.data);
            } catch (error) {
                console.error('Error fetching on the air:', error);
            }
        }
        fetchSelectedVideos();

        // recommend videos
        const fetchRecommendVideos = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/recommend`);
                setRecommendVideos(response.data);
            } catch (error) {
                console.error('Error fetching recommend videos:', error);
            }
        }
        fetchRecommendVideos();

        // 信仰问答
        const fetchFrequentlyAskedQuestions = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/faq`);
                setFrequentlyAskedQuestions(response.data);
            } catch (error) {
                console.error('Error fetching frequently asked questions:', error);
            }
        }
        fetchFrequentlyAskedQuestions();

    }, []);

    useEffect(() => {
        seriesSlides[currentSlide] && setSeriesCarrousel(seriesSlides[currentSlide].articles);
        setCurrentCarrouselPage(1);
    }, [currentSlide, seriesSlides]);

    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = frequentlyAskedQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
    const totalPages = Math.ceil(frequentlyAskedQuestions.length / questionsPerPage);

    // rewrite pageNumbers as function, create an array of page numbers
    const pageNumbers = (number) => {
        const pageNumbersArr = [];
        for (let i = 1; i <= number; i++) {
            pageNumbersArr.push(i);
        }
        return pageNumbersArr;
    }

    const handleQAPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Auto-play slides
    useEffect(() => {
        let interval;
        if (isAutoPlaying) {
            // Auto-turn slides every 5 seconds
            interval = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % seriesSlides.length);
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [seriesSlides, isAutoPlaying]);

    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % seriesSlides.length);
        setIsAutoPlaying(false);
    };

    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + seriesSlides.length) % seriesSlides.length);
        setIsAutoPlaying(false);
    };

    const toggleAutoPlay = () => {
        setIsAutoPlaying((prev) => !prev);
    };

    if (seriesSlides.length === 0) {
        return <div>Loading slides...</div>;
    }

    const inlineStyle = {
        backgroundImage: `url(${seriesSlides[currentSlide].series.cover_url})`,
    }

    // recommend videos
    const getCurrentRecommendVideoPageData = () => {
        const startIndex = (currentRecommendVideoPage - 1) * recommendVideosPerPage;
        // console.log(recommendVideos, recommendVideos.slice(startIndex, startIndex + recommendVideosPerPage), startIndex, startIndex + recommendVideosPerPage);
        return recommendVideos.slice(startIndex, startIndex + recommendVideosPerPage);
    }

    const handleRecommendVideoPagePrev = () => {
        setCurrentRecommendVideoPage((prev) => Math.max(prev - 1, 1));
    }

    const handleRecommendVideoPageNext = () => {
        setCurrentRecommendVideoPage((prev) => Math.min(prev + 1, totalRecommendVideoPages));
    }

    // selected videos
    const getCurrentSelectedVideoPageData = () => {
        const startIndex = (currentSelectedVideoPage - 1) * videosPerPage;
        return selectedVideos.slice(startIndex, startIndex + videosPerPage);
    }

    const handleSelectedVideoPagePrev = () => {
        setCurrentSelectedVideoPage((prev) => Math.max(prev - 1, 1));
    }

    const handleSelectedVideoPageNext = () => {
        setCurrentSelectedVideoPage((prev) => Math.min(prev + 1, totalSelectedVideoPages));
    }

    // series carrousel
    const getCurrentPageData = () => {

        // when pre/next icon reaches the start and the end of the list , it only show the top 5 items or the last 5 items
        if (currentCarrouselPage === 1) {
            return seriesCarrousel.slice(0, itemsPerCarrouselPage);
        } else if (currentCarrouselPage === totalCarrouselPages) {
            return seriesCarrousel.slice(-itemsPerCarrouselPage);
        } else {
            return seriesCarrousel.slice((currentCarrouselPage - 1) * itemsPerCarrouselPage, currentCarrouselPage * itemsPerCarrouselPage);
        }
    };

    const prevCarrouselPage = () => {
        setCurrentCarrouselPage((prev) => Math.max(prev - 1, 1));
    };

    const nextCarrouselPage = () => {
        setCurrentCarrouselPage((prev) => Math.min(prev + 1, totalCarrouselPages));
    };

    return (
        <>
            <header className='header'>
                <div className="header__upside" style={inlineStyle}>
                    <div className="container">
                        <Nav />
                        <div className="hero">

                            <div className="hero-upside">

                                <h6 className="hero-info">
                                    {/* time, subtitle, ratings, year */}
                                    <span className="time"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5.14941 9.41291L7.38025 7.73979V4.3316C7.38025 3.98891 7.65725 3.71191 7.99994 3.71191C8.34263 3.71191 8.61963 3.98891 8.61963 4.3316V8.04966C8.61963 8.24485 8.52791 8.42891 8.37175 8.54541L5.89307 10.4044C5.78154 10.4881 5.65138 10.5284 5.52188 10.5284C5.33288 10.5284 5.14697 10.4435 5.0255 10.2799C4.81972 10.0066 4.8755 9.61804 5.14941 9.41291Z" fill="white" />
                                        <path d="M8 0C12.4115 0 16 3.58853 16 8C16 12.4115 12.4115 16 8 16C3.58853 16 0 12.4115 0 8C0 3.58853 3.58853 0 8 0ZM8 14.7607C11.7273 14.7607 14.7607 11.7273 14.7607 8C14.7607 4.27266 11.7273 1.23934 8 1.23934C4.27203 1.23934 1.23934 4.27266 1.23934 8C1.23934 11.7273 4.27266 14.7607 8 14.7607Z" fill="white" />
                                    </svg>{seriesSlides[currentSlide].data_status}</span>
                                    <span className="subtitle"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                        <path d="M17.0711 1.96458C16.8423 1.7693 16.4712 1.7693 16.2424 1.96458C16.0136 2.15986 16.0136 2.47653 16.2424 2.67185C19.6845 5.60979 19.6845 10.3902 16.2424 13.3282C16.0136 13.5235 16.0136 13.8402 16.2424 14.0355C16.3568 14.1332 16.5068 14.182 16.6567 14.182C16.8066 14.182 16.9566 14.1332 17.071 14.0355C18.9598 12.4234 20 10.2799 20 8.00003C20 5.72011 18.9598 3.5767 17.0711 1.96458Z" fill="white" />
                                        <path d="M15.2957 3.47971C15.0669 3.28443 14.6959 3.28443 14.467 3.47971C14.2382 3.67503 14.2382 3.99167 14.467 4.18698C15.6604 5.20549 16.3175 6.55965 16.3175 8.00003C16.3175 9.44038 15.6604 10.7945 14.467 11.813C14.2382 12.0083 14.2382 12.3249 14.467 12.5203C14.5814 12.618 14.7314 12.6668 14.8813 12.6668C15.0313 12.6668 15.1813 12.6179 15.2956 12.5203C16.7103 11.3129 17.4894 9.70758 17.4894 8.00003C17.4894 6.29246 16.7103 4.68714 15.2957 3.47971Z" fill="white" />
                                        <path d="M12.2644 0.129346C11.8241 -0.0787041 11.2651 -0.0341934 10.7304 0.251475L4.18324 3.75005H2.15492C0.96668 3.75005 0 4.57515 0 5.58936V10.4106C0 11.4248 0.96668 12.2499 2.15492 12.2499H4.18324L10.7303 15.7485C11.0427 15.9154 11.3634 16 11.6639 16C11.8777 16 12.0813 15.9572 12.2644 15.8706C12.7046 15.6626 12.9571 15.2344 12.9571 14.6961V1.3039C12.9571 0.765532 12.7046 0.337429 12.2644 0.129346ZM3.76559 11.2497H2.15492C1.61285 11.2497 1.17187 10.8733 1.17187 10.4106V5.58936C1.17187 5.12668 1.61285 4.75029 2.15492 4.75029H3.76559V11.2497ZM11.7852 14.6961C11.7852 14.9079 11.7143 14.9873 11.6967 14.9956C11.6791 15.0038 11.5627 15.0131 11.3522 14.9007L4.93746 11.4728V4.52714L11.3522 1.09925C11.5627 0.986752 11.6791 0.996121 11.6967 1.00436C11.7142 1.01266 11.7852 1.09204 11.7852 1.30386V14.6961Z" fill="white" />
                                    </svg>{seriesSlides[currentSlide].name}</span>
                                    <span className="ratings"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                        <path d="M14.8406 5.82501L11.5537 8.96331L12.3299 13.3958C12.3637 13.5896 12.2824 13.7855 12.1199 13.9013C12.0281 13.9669 11.9188 14 11.8096 14C11.7257 14 11.6413 13.9804 11.5642 13.9406L7.5 11.8478L3.4363 13.94C3.259 14.032 3.04318 14.0171 2.88065 13.9008C2.71813 13.785 2.63686 13.5891 2.67063 13.3953L3.44686 8.9628L0.159386 5.82501C0.0158556 5.68753 -0.0363851 5.48182 0.0258816 5.29524C0.0881483 5.10866 0.253314 4.97169 0.45225 4.94327L4.99508 4.29721L7.02667 0.264756C7.2045 -0.0882519 7.7955 -0.0882519 7.97333 0.264756L10.0049 4.29721L14.5477 4.94327C14.7467 4.97169 14.9119 5.10814 14.9741 5.29524C15.0364 5.48234 14.9841 5.68701 14.8406 5.82501Z" fill="#0C24FF" />
                                    </svg>{seriesSlides[currentSlide].play_count} 播放</span>
                                    <span className="year"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M2.20308 16H13.7969C15.0123 16 16 15.0489 16 13.8785V3.30665C16 2.13628 15.0123 1.18516 13.7969 1.18516H12.9231V0.592582C12.9231 0.266652 12.6461 0 12.3077 0C11.9692 0 11.6923 0.266652 11.6923 0.592582V1.18516H4.30771V0.592582C4.30771 0.266652 4.03075 0 3.69229 0C3.35382 0 3.07692 0.266652 3.07692 0.592582V1.18516H2.20308C0.9877 1.18516 0 2.13628 0 3.30665V13.8785C0 15.0489 0.9877 16 2.20308 16ZM1.2308 3.30665C1.2308 2.79111 1.66771 2.37038 2.20308 2.37038H3.07692V2.96296C3.07692 3.28889 3.35382 3.55554 3.69229 3.55554C4.03075 3.55554 4.30766 3.28889 4.30766 2.96296V2.37038H11.6923V2.96296C11.6923 3.28889 11.9692 3.55554 12.3077 3.55554C12.6461 3.55554 12.923 3.28889 12.923 2.96296V2.37038H13.7969C14.3322 2.37038 14.7692 2.79111 14.7692 3.30665V5.03704H1.2308V3.30665ZM1.2308 6.22221H14.7693V13.8785C14.7693 14.3941 14.3323 14.8148 13.797 14.8148H2.20308C1.66771 14.8148 1.2308 14.3941 1.2308 13.8785V6.22221Z" fill="white" />
                                    </svg>{seriesSlides[currentSlide].comment_count} 评论</span>
                                </h6>
                                <h1 className="hero-title">{seriesSlides[currentSlide].series.name}</h1>
                                <h1 className="hero-title">{seriesSlides[currentSlide].series.alias_name}</h1>
                                {/* <div className="hero-tags" >
                                    <span className="tag">Fantasy</span>
                                    <span className="tag">Sci-Fi</span>
                                </div> */}
                                <p className="hero-text invisible">{seriesSlides[currentSlide].series.description}</p>
                                <a href={`/seriesonplay/${seriesSlides[currentSlide].series.id}`} className="btn">立即播放</a>

                            </div>
                            <div className="hero-downside">
                                <div className="hero-downside--items">
                                    <a href="#" className="sliderIcon--prev" onClick={goToPreviousSlide}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                                    </svg></a>
                                    {
                                        seriesSlides.map((_, index) => (
                                            <a href="#" className="sliderIcon-dot" onClick={() => setCurrentSlide(index)} key={index}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                                                    <ellipse cx="5.51576" cy="4.5" rx="4.53822" ry="4.5" fill="white"
                                                        fillOpacity={currentSlide === index ? 0.18 : 1}
                                                    /></svg>
                                            </a>
                                        ))
                                    }
                                    <a href="#" className="sliderIcon--next" onClick={goToNextSlide}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                        // fillOpacity="0.18" 
                                        />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* —————————————————————————————— */}
                <section className="ontheair">
                    <div className="container">
                        <div className="ontheair__upside">
                            <h1 className="ontheair__upside--title">正在播放</h1>
                            <a href="#" className="ontheair__upside--more">查看更多</a>
                        </div>
                        <div className="ontheair__items">
                            <span
                                className="ontheair__items--previous"
                                onClick={prevCarrouselPage}
                                style={currentCarrouselPage === 1 ? { cursor: 'not-allowed' } : {}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="90" viewBox="0 0 93 90" fill="none">
                                    <g filter="url(#filter0_d_173_1085)">
                                        <ellipse cx="26.3234" cy="25" rx="26.3234" ry="25" transform="matrix(-1 0 0 1 72.6465 16)" fill="white" />
                                    </g>
                                    <path d="M40.3485 40.2976L49.9194 32.2876C50.1408 32.1021 50.4363 32 50.7513 32C51.0664 32 51.3619 32.1021 51.5833 32.2876L52.2881 32.8773C52.7468 33.2616 52.7468 33.8862 52.2881 34.2699L44.2512 40.9963L52.297 47.7301C52.5184 47.9155 52.6406 48.1627 52.6406 48.4263C52.6406 48.6901 52.5184 48.9373 52.297 49.1228L51.5922 49.7124C51.3707 49.8979 51.0753 50 50.7603 50C50.4452 50 50.1497 49.8979 49.9283 49.7124L40.3485 41.695C40.1266 41.509 40.0047 41.2607 40.0054 40.9967C40.0047 40.7317 40.1266 40.4835 40.3485 40.2976Z" fill="#172639" />
                                    <defs>
                                        <filter id="filter0_d_173_1085" x="0" y="0" width="92.6465" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            {/* <feFlood fillopacity="0" result="BackgroundImageFix" /> */}
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_1085" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_1085" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            {
                                getCurrentPageData().map((item, index) => (
                                    <div className="ontheair__item" key={index}>
                                        <a href={`/onplay/${item.id}`}><img src="https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg" alt={item.title} /></a>
                                        <div className="ontheair__item--tags">
                                            {/* <span className="tag">{item.language}</span>
                                                <span className="tag">{item.create_datetime.substring(0, 4)}</span> */}
                                        </div>
                                        <a href={`/onplay/${item.id}`}><h2 className="on_the_air--item-title">
                                            {item.video_name}
                                        </h2></a>
                                    </div>
                                ))
                            }
                            <span
                                className="ontheair__items--next"
                                onClick={nextCarrouselPage}
                                style={currentCarrouselPage === totalCarrouselPages ? { cursor: 'not-allowed' } : {}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="90" viewBox="0 0 93 90" fill="none">
                                    <g filter="url(#filter0_d_173_1082)">
                                        <ellipse cx="46.6766" cy="41" rx="26.3234" ry="25" fill="white" />
                                    </g>
                                    <path d="M52.6513 40.2976L43.0804 32.2876C42.859 32.1021 42.5635 32 42.2484 32C41.9333 32 41.6378 32.1021 41.4165 32.2876L40.7116 32.8773C40.253 33.2616 40.253 33.8862 40.7116 34.2699L48.7486 40.9963L40.7027 47.7301C40.4814 47.9155 40.3591 48.1627 40.3591 48.4263C40.3591 48.6901 40.4814 48.9373 40.7027 49.1228L41.4075 49.7124C41.6291 49.8979 41.9244 50 42.2395 50C42.5546 50 42.8501 49.8979 43.0714 49.7124L52.6513 41.695C52.8732 41.509 52.995 41.2607 52.9943 40.9967C52.995 40.7317 52.8732 40.4835 52.6513 40.2976Z" fill="#172639" />
                                    <defs>
                                        <filter id="filter0_d_173_1082" x="0.353271" y="0" width="92.6467" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            {/* <feFlood fillOpacity="0" result="BackgroundImageFix" /> */}
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_1082" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_1082" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>

                        </div>
                    </div>
                </section>
            </header >
            {/* ----------------------- */}
            <section className="selected">
                <div className="container">
                    <div className="selected__col--1st">
                        <h1 className="section-title">精选视频</h1>
                        <ul className="selected__tags">
                            <li><a href="#" className="tag">瘾症</a></li>
                            <li><a href="#" className="tag">亲子关系</a></li>
                            <li><a href="#" className="tag">失败婚姻</a></li>
                            <li><a href="#" className="tag">Anne Black</a></li>
                            <li><a href="#" className="tag">Warren French</a></li>
                            <li><a href="#" className="tag">Cecelia Munoz</a></li>
                            <li><a href="#" className="tag">Henrietta Stewart</a></li>
                            <li><a href="#" className="tag">Milton Richardson</a></li>
                        </ul>
                        <span>
                            <a href="#" className="btn">查看更多</a>
                        </span>
                    </div>
                    <div className="selected__col--2nd">
                        <h1 className="section-title">改变的力量</h1>
                        <p className="section-text">
                            事业失败，情感挫折，严重瘾症，对生活失望，内心空虚，没有价值感……，如果你正遭遇这些问题困扰，请看信仰改变的力量。
                        </p>
                    </div>
                    <div className="selected__items">
                        {
                            getCurrentSelectedVideoPageData()
                                .map((item, index) => (
                                    <div className="selected__item" key={index}>
                                        <div className="selected__item--upside">
                                            <a href={`/onplay/${item.id}`}>
                                                <h1 className="title">{item.title}</h1>
                                            </a>
                                            <div className="info">
                                                <div className="info__reviews">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="66" height="13" viewBox="0 0 66 13" fill="none">
                                                            <path d="M7.20724 0.550409L8.73907 3.6284C8.83559 3.82135 9.02084 3.95564 9.23723 3.98652L12.662 4.48048C13.2038 4.5592 13.4202 5.21987 13.0279 5.59806L10.5496 7.99377C10.3939 8.14504 10.3223 8.36115 10.3596 8.57417L10.945 11.9563C11.0368 12.4919 10.4702 12.8994 9.98602 12.6478L6.92236 11.0501C6.72932 10.9498 6.49893 10.9498 6.30745 11.0501L3.24223 12.6478C2.75653 12.9009 2.18988 12.4919 2.28328 11.9563L2.86862 8.57417C2.90598 8.36115 2.83437 8.14504 2.67869 7.99377L0.20037 5.59806C-0.191928 5.21833 0.0244588 4.55766 0.566203 4.48048L3.99102 3.98652C4.20585 3.95564 4.39266 3.82135 4.48918 3.6284L6.02101 0.550409C6.26386 0.062624 6.96439 0.062624 7.20724 0.550409Z" fill="#FFB800" />
                                                            <path d="M27.8015 0.550043L29.3333 3.62803C29.4298 3.82098 29.6151 3.95528 29.8315 3.98615L33.2563 4.48011C33.798 4.55884 34.0144 5.21951 33.6221 5.5977L31.1438 7.9934C30.9881 8.14468 30.9165 8.36078 30.9539 8.5738L31.5392 11.9559C31.6311 12.4915 31.0644 12.899 30.5803 12.6474L27.5166 11.0498C27.3236 10.9494 27.0932 10.9494 26.9017 11.0498L23.8365 12.6474C23.3508 12.9006 22.7841 12.4915 22.8775 11.9559L23.4629 8.5738C23.5002 8.36078 23.4286 8.14468 23.2729 7.9934L20.7946 5.5977C20.4023 5.21796 20.6187 4.55729 21.1604 4.48011L24.5853 3.98615C24.8001 3.95528 24.9869 3.82098 25.0834 3.62803L26.6152 0.550043C26.8581 0.0622578 27.5586 0.0622578 27.8015 0.550043Z" fill="#FFB800" />
                                                            <path d="M41.0861 0.550165L42.618 3.62815C42.7145 3.82111 42.8997 3.9554 43.1161 3.98627L46.541 4.48023C47.0827 4.55896 47.2991 5.21963 46.9068 5.59782L44.4285 7.99352C44.2728 8.1448 44.2012 8.3609 44.2385 8.57393L44.8239 11.956C44.9157 12.4916 44.3491 12.8992 43.8649 12.6476L40.8013 11.0499C40.6082 10.9496 40.3778 10.9496 40.1864 11.0499L37.1211 12.6476C36.6354 12.9007 36.0688 12.4916 36.1622 11.956L36.7475 8.57393C36.7849 8.3609 36.7133 8.1448 36.5576 7.99352L34.0793 5.59782C33.687 5.21809 33.9034 4.55741 34.4451 4.48023L37.8699 3.98627C38.0848 3.9554 38.2716 3.82111 38.3681 3.62815L39.8999 0.550165C40.1428 0.0623799 40.8433 0.0623799 41.0861 0.550165Z" fill="#FFB800" />
                                                            <path d="M59.0217 0.550287L60.5535 3.62827C60.65 3.82123 60.8353 3.95552 61.0517 3.9864L64.4765 4.48036C65.0182 4.55908 65.2346 5.21975 64.8423 5.59794L62.364 7.99364C62.2083 8.14492 62.1367 8.36103 62.1741 8.57405L62.7594 11.9561C62.8513 12.4918 62.2846 12.8993 61.8005 12.6477L58.7368 11.05C58.5438 10.9497 58.3134 10.9497 58.1219 11.05L55.0567 12.6477C54.571 12.9008 54.0043 12.4918 54.0977 11.9561L54.6831 8.57405C54.7204 8.36103 54.6488 8.14492 54.4931 7.99364L52.0148 5.59794C51.6225 5.21821 51.8389 4.55754 52.3807 4.48036L55.8055 3.9864C56.0203 3.95552 56.2071 3.82123 56.3036 3.62827L57.8355 0.550287C58.0783 0.0625019 58.7788 0.0625019 59.0217 0.550287Z" fill="#FFB800" />
                                                        </svg>
                                                    </span>
                                                    <span>
                                                        {item.view_count} Reviews
                                                    </span>
                                                </div>
                                                <div className="info__love">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                                        <rect x="0.331543" y="0.891602" width="46.4945" height="46.1029" rx="20" fill="#E4E4E4" />
                                                        <path d="M32.1919 15.854C31.1027 14.7026 29.6491 14.0692 28.0997 14.0692C26.5565 14.0692 25.1131 14.699 24.0349 15.8431L23.6144 16.2905L23.1939 15.8438C22.1122 14.6961 20.6654 14.0641 19.1181 14.0641C17.5721 14.0641 16.1226 14.6953 15.0354 15.8438C12.7887 18.2301 12.7873 22.1243 15.0354 24.5244L23.131 33.1135C23.2643 33.2544 23.4393 33.3256 23.6144 33.3256C23.7894 33.3256 23.9638 33.2544 24.0978 33.1135L32.1947 24.5222C34.438 22.1273 34.4373 18.2388 32.1919 15.854ZM30.7403 22.9844C30.6063 23.1268 30.4313 23.1987 30.2556 23.1987C30.0812 23.1987 29.9069 23.1283 29.7728 22.9873C29.5055 22.704 29.5041 22.2449 29.7708 21.9602C30.672 20.9977 30.6747 19.3778 29.7763 18.4233C29.5089 18.1393 29.5089 17.6802 29.7763 17.3961C30.0436 17.1121 30.4757 17.1121 30.7431 17.3961C32.1646 18.9063 32.1632 21.4662 30.7403 22.9844Z" fill="#FF055F" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <a href={`/onplay/${item.id}`}>
                                            <img src="https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg" alt="make a change" />
                                        </a>
                                        {/* <img src={ImgSelected1} alt="make a change" /> */}
                                    </div>
                                ))
                        }
                    </div>

                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleSelectedVideoPagePrev(currentSelectedVideoPage - 1)}
                            style={{ cursor: currentSelectedVideoPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalSelectedVideoPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentSelectedVideoPage === number ? 'bold_number' : ''}`}
                                    onClick={() => setCurrentSelectedVideoPage(number)}
                                    style={{ cursor: currentSelectedVideoPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleSelectedVideoPageNext(currentSelectedVideoPage + 1)}
                            style={{ cursor: currentSelectedVideoPage === totalSelectedVideoPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>
            <section className="recommandations">
                <div className="container">
                    <div className="recommandations__upsdie">
                        <div className="recommandations__upsdie--title">
                            <h1 className="section-title">主题推荐</h1>
                            <span>
                                <a href="#" className="btn">查看更多</a>
                            </span>
                        </div>
                        <ul className="recommandations__upsdie--tags">
                            <li><a href="#">职业</a></li>
                            <li><a href="#">超自然</a></li>
                            <li><a href="#">人文</a></li>
                            <li><a href="#">科学与信仰</a></li>
                            <li><a href="#">home</a></li>
                            <li><a href="#">anyone</a></li>
                            <li><a href="#">rapidly</a></li>
                            <li><a href="#">pain</a></li>
                        </ul>
                    </div>
                    <div className="recommandations__items">
                        <div className="col">
                            {
                                getCurrentRecommendVideoPageData()
                                    .slice(0, 5)
                                    .map((item, index) => (
                                        <div className="recommandations__item" key={index}>
                                            <a href={`/seriesonplay/${item.id}`}>
                                                <img src="https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg" alt="recommandation" />
                                            </a>
                                            <div className="recommandations__item--downside">
                                                <a href={`/seriesonplay/${item.id}`}>
                                                    <h1 className="title">{item.name}</h1>
                                                </a>
                                                <p className="tags">
                                                <span>{item.play_count} 次播放</span>
                                                <span>{item.like_count} 次喜欢</span>
                                                </p>
                                                <div className="stars">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                    </svg>
                                                    <span>8.7</span>
                                                </div>
                                                {/* <div className="more_tags">
                                                    <span>IMDb</span>
                                                    <span>HD</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    ))
                            }

                        </div>
                        <div className="col">
                            {getCurrentRecommendVideoPageData()
                                .slice(5, 10)
                                .map((item, index) => (
                                    <div className="recommandations__item" key={index}>
                                        <a href={`/seriesonplay/${item.id}`}>
                                            <img src="https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg" alt="recommandation" />
                                        </a>
                                        <div className="recommandations__item--downside">
                                            <a href={`/seriesonplay/${item.id}`}>
                                                    <h1 className="title">{item.name}</h1>
                                                </a>
                                            {/* <div className="inner__html" dangerouslySetInnerHTML={{ __html: item.description}} />  */}
                                            <p className="tags">
                                                <span>{item.play_count} 次播放</span>
                                                <span>{item.like_count} 次喜欢</span>
                                            </p>
                                            <div className="stars">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.275 11.9188L11.425 13.9188C11.8312 14.175 12.3312 13.7938 12.2125 13.325L11.3 9.73752C11.2753 9.63807 11.2792 9.53369 11.3113 9.43638C11.3434 9.33907 11.4023 9.2528 11.4812 9.18752L14.3062 6.83127C14.675 6.52502 14.4875 5.90627 14.0062 5.87502L10.3187 5.63752C10.2181 5.63166 10.1214 5.59663 10.0404 5.53669C9.95935 5.47675 9.89754 5.39451 9.8625 5.30002L8.4875 1.83752C8.4511 1.73745 8.38479 1.65102 8.29758 1.58994C8.21037 1.52886 8.10647 1.49609 8 1.49609C7.89352 1.49609 7.78963 1.52886 7.70241 1.58994C7.6152 1.65102 7.54889 1.73745 7.5125 1.83752L6.1375 5.30002C6.10245 5.39451 6.04064 5.47675 5.95962 5.53669C5.87859 5.59663 5.78186 5.63166 5.68125 5.63752L1.99375 5.87502C1.5125 5.90627 1.325 6.52502 1.69375 6.83127L4.51875 9.18752C4.59771 9.2528 4.65661 9.33907 4.68868 9.43638C4.72075 9.53369 4.72467 9.63807 4.7 9.73752L3.85625 13.0625C3.7125 13.625 4.3125 14.0813 4.79375 13.775L7.725 11.9188C7.8072 11.8665 7.90259 11.8387 8 11.8387C8.09741 11.8387 8.1928 11.8665 8.275 11.9188Z" fill="#FDDC33" />
                                                </svg>
                                                <span>8.7</span>
                                            </div>
                                            <div className="more_tags">
                                                <span>IMDb</span>
                                                <span>HD</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleRecommendVideoPagePrev(currentRecommendVideoPage - 1)}
                            style={{ cursor: currentRecommendVideoPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalRecommendVideoPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentRecommendVideoPage === number ? 'bold_number' : ''}`}
                                    onClick={() => setCurrentRecommendVideoPage(number)}
                                    style={{ cursor: currentRecommendVideoPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleRecommendVideoPageNext(currentRecommendVideoPage + 1)}
                            style={{ cursor: currentRecommendVideoPage === totalRecommendVideoPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>

                </div>
            </section>
            <section className="series">
                <div className="container">
                    <div className="series__upside">
                        <div className="series__upside--title">
                            <h1>
                                系列片
                            </h1>
                            <span>
                                <a href="#" className="btn">查看更多</a>
                            </span>
                        </div>
                        <ul className="series__upside--tags">
                            <li><a href="#">全部</a></li>
                            <li><a href="#">见证专辑</a></li>
                            <li><a href="#">人物专访</a></li>
                            <li><a href="#">record</a></li>
                            <li><a href="#">ability</a></li>
                            <li><a href="#">well</a></li>
                            <li><a href="#">affect</a></li>
                            <li><a href="#">音乐</a></li>
                        </ul>
                    </div>
                    <div className="series__section">
                        <div className="series__items">
                            <div className="series__item">
                                <a href="#">
                                    <img src={ImgSeries1} alt="poster of series 1" />
                                </a>
                                <div className="content">
                                    <div className="tags">
                                        <div className="tags__pre">
                                            <span>2017年</span>
                                            <span>美国</span>
                                            <span>2小时30分钟</span>
                                        </div>
                                        <span className="tags__after"> 亲子 </span>
                                    </div>
                                    <h1 className="title">The Path of the Just</h1>
                                    <div className="intro__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </div>
                                    <a href="#" className="btn">立即播放</a>
                                </div>
                            </div>
                            <div className="series__item">
                                <a href="#">
                                    <img src={ImgSeries2} alt="poster of series 1" />
                                </a>
                                <div className="content">
                                    <div className="tags">
                                        <div className="tags__pre">
                                            <span>2017年</span>
                                            <span>英国</span>
                                            <span>2小时30分钟</span>
                                        </div>
                                        <span className="tags__after"> 亲子 </span>
                                    </div>
                                    <h1 className="title">Don't Loose Hope</h1>
                                    <div className="intro__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </div>
                                    <a href="#" className="btn">立即播放</a>
                                </div>
                            </div>
                            <div className="series__item">
                                <a href="#">
                                    <img src={ImgSeries3} alt="poster of series 1" />
                                </a>
                                <div className="content">
                                    <div className="tags">
                                        <div className="tags__pre">
                                            <span>2017年</span>
                                            <span>美国</span>
                                            <span>2小时30分钟</span>
                                        </div>
                                        <span className="tags__after">爱情</span>
                                    </div>
                                    <h1 className="title">Holy is the Lord</h1>
                                    <div className="intro__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </div>
                                    <a href="#" className="btn">立即播放</a>
                                </div>
                            </div>
                        </div>
                        <div className="paging">
                            <span>
                                <img src={btnPrev} alt="button previous" />
                            </span>
                            <span>
                                <img src={btnNext} alt="button next" />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="evangelize">
                <div className="container">
                    <h1 className="evangelize__title">传递福音</h1>
                    <div className="evangelize__body">
                        <div className="evangelize__left">
                            <div className="evangelize__left--upside">
                                <div className="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="65" viewBox="0 0 69 65" fill="none">
                                        <g clipPath="url(#clip0_87_2411)">
                                            <path d="M48.1095 0.487793H13.209C9.83649 0.487793 7.09375 3.10328 7.09375 6.31307V58.7402C7.09375 61.9529 9.83649 64.5655 13.2092 64.5655H56.0166C59.3893 64.5655 62.132 61.9529 62.132 58.7402V13.8451L48.1095 0.487793ZM22.3821 50.0023V26.7014L46.8436 38.3605L22.3821 50.0023ZM43.7859 17.9635V3.40037L59.0743 17.9635H43.7859Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_87_2411">
                                                <rect width="67.269" height="64.0776" fill="white" transform="translate(0.978271 0.487793)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className="left__title">
                                        <h1>Rework</h1>
                                        <div className="left__title--info">
                                            <span>2018</span>
                                            <span>英文</span>
                                            <span>2小时30分钟</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="right__info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="20" viewBox="0 0 97 20" fill="none">
                                            <path d="M11.5004 1.29203L13.7506 5.81334C13.8923 6.09677 14.1645 6.29404 14.4823 6.33939L19.5131 7.06497C20.3089 7.18062 20.6267 8.15109 20.0505 8.70661L16.41 12.2257C16.1813 12.4479 16.0761 12.7654 16.131 13.0783L16.9908 18.0463C17.1258 18.8331 16.2934 19.4317 15.5822 19.0621L11.082 16.7153C10.7984 16.5679 10.46 16.5679 10.1787 16.7153L5.67615 19.0621C4.96269 19.434 4.13033 18.8331 4.26753 18.0463L5.12734 13.0783C5.18222 12.7654 5.07703 12.4479 4.84836 12.2257L1.2079 8.70661C0.631648 8.14882 0.949502 7.17835 1.74528 7.06497L6.77606 6.33939C7.09163 6.29404 7.36604 6.09677 7.50781 5.81334L9.75794 1.29203C10.1147 0.575509 11.1437 0.575509 11.5004 1.29203Z" fill="#FFB800" />
                                            <path d="M41.7482 1.29203L43.9984 5.81334C44.1401 6.09677 44.4123 6.29404 44.7301 6.33939L49.7609 7.06497C50.5567 7.18062 50.8745 8.15109 50.2983 8.70661L46.6578 12.2257C46.4291 12.4479 46.324 12.7654 46.3788 13.0783L47.2386 18.0463C47.3736 18.8331 46.5412 19.4317 45.83 19.0621L41.3298 16.7153C41.0462 16.5679 40.7078 16.5679 40.4265 16.7153L35.924 19.0621C35.2105 19.434 34.3781 18.8331 34.5153 18.0463L35.3751 13.0783C35.43 12.7654 35.3248 12.4479 35.0962 12.2257L31.4557 8.70661C30.8795 8.14882 31.1973 7.17835 31.9931 7.06497L37.0239 6.33939C37.3394 6.29404 37.6138 6.09677 37.7556 5.81334L40.0057 1.29203C40.3625 0.575509 41.3915 0.575509 41.7482 1.29203Z" fill="#FFB800" />
                                            <path d="M61.2643 1.29203L63.5145 5.81334C63.6562 6.09677 63.9284 6.29404 64.2462 6.33939L69.277 7.06497C70.0728 7.18062 70.3906 8.15109 69.8144 8.70661L66.1739 12.2257C65.9453 12.4479 65.8401 12.7654 65.8949 13.0783L66.7548 18.0463C66.8897 18.8331 66.0573 19.4317 65.3461 19.0621L60.8459 16.7153C60.5623 16.5679 60.2239 16.5679 59.9426 16.7153L55.4401 19.0621C54.7266 19.434 53.8942 18.8331 54.0314 18.0463L54.8913 13.0783C54.9461 12.7654 54.8409 12.4479 54.6123 12.2257L50.9718 8.70661C50.3956 8.14882 50.7134 7.17835 51.5092 7.06497L56.54 6.33939C56.8555 6.29404 57.13 6.09677 57.2717 5.81334L59.5219 1.29203C59.8786 0.575509 60.9076 0.575509 61.2643 1.29203Z" fill="#FFB800" />
                                            <path d="M87.6096 1.29203L89.8597 5.81334C90.0015 6.09677 90.2736 6.29404 90.5914 6.33939L95.6222 7.06497C96.418 7.18062 96.7358 8.15109 96.1596 8.70661L92.5191 12.2257C92.2905 12.4479 92.1853 12.7654 92.2402 13.0783L93.1 18.0463C93.2349 18.8331 92.4025 19.4317 91.6913 19.0621L87.1911 16.7153C86.9075 16.5679 86.5691 16.5679 86.2878 16.7153L81.7853 19.0621C81.0718 19.434 80.2395 18.8331 80.3767 18.0463L81.2365 13.0783C81.2913 12.7654 81.1862 12.4479 80.9575 12.2257L77.317 8.70661C76.7408 8.14882 77.0586 7.17835 77.8544 7.06497L82.8852 6.33939C83.2008 6.29404 83.4752 6.09677 83.6169 5.81334L85.8671 1.29203C86.2238 0.575509 87.2528 0.575509 87.6096 1.29203Z" fill="#FFB800" />
                                        </svg>
                                        <span>345 reviews</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
                                        <rect x="0.604248" y="0.917358" width="68.2966" height="67.7214" rx="20" fill="#E4E4E4" />
                                        <path d="M47.4048 22.8955C45.8048 21.2043 43.6695 20.2738 41.3936 20.2738C39.1268 20.2738 37.0066 21.1989 35.4227 22.8795L34.805 23.5368L34.1873 22.8806C32.5984 21.1947 30.4732 20.2664 28.2003 20.2664C25.9295 20.2664 23.8002 21.1936 22.2033 22.8806C18.903 26.3858 18.901 32.1062 22.2033 35.6317L34.0949 48.2484C34.2908 48.4554 34.5479 48.56 34.805 48.56C35.0621 48.56 35.3183 48.4554 35.5151 48.2484L47.4088 35.6285C50.7041 32.1105 50.7031 26.3986 47.4048 22.8955ZM45.2725 33.3696C45.0756 33.5787 44.8185 33.6844 44.5604 33.6844C44.3043 33.6844 44.0482 33.5809 43.8513 33.3739C43.4586 32.9577 43.4566 32.2834 43.8483 31.8651C45.1721 30.4512 45.1761 28.0718 43.8563 26.6697C43.4636 26.2524 43.4636 25.5781 43.8563 25.1609C44.249 24.7437 44.8838 24.7437 45.2765 25.1609C47.3646 27.3792 47.3626 31.1395 45.2725 33.3696Z" fill="#FF055F" />
                                    </svg>
                                </div>
                            </div>
                            <img src={ImgEvangelize} alt="image of evangelize" />
                        </div>
                        <div className="evangelize__right">
                            <img src={ImgEvangelize1} alt="image 1 of evangelize" />
                            <img src={ImgEvangelize2} alt="image 2 of evangelize" />
                            <img src={ImgEvangelize3} alt="image 3 of evangelize" />
                            <img src={ImgEvangelize4} alt="image 4 of evangelize" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="answered">
                <div className="container">
                    <h1 className="answered__title">信仰问答</h1>
                    <p className="answered__subtitle">深度探讨信仰的核心问题，解答关于信仰生活中的疑惑与挑战，帮助您更好地理解和实践信仰的真理 </p>
                    <div className="answered__items">

                        {currentQuestions && currentQuestions
                            .map((question, index) => {
                                return (
                                    <div className={`answered__item answered__item--${index + 1}`}
                                        key={question.id}
                                        onClick={() => {
                                            if (selectedQuestion === question.id) {
                                                setSelectedQuestion(0)
                                            } else {
                                                setSelectedQuestion(question.id)
                                            }
                                        }}
                                    >
                                        <div className="answered__item--title">
                                            <h1>{question.title}</h1>
                                            <span>
                                                {question.id === selectedQuestion ?
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        // onClick={() => setSelectedQuestion(0)}
                                                        width="52" height="51" viewBox="0 0 52 51" fill="none">
                                                        <ellipse cx="25.9545" cy="25.3309" rx="25.0941" ry="25.3309" fill="#DB5443" />
                                                        <path d="M17.7749 22.2212L25.9543 30.4405L34.1338 22.2212" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg> :
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        // onClick={() => setSelectedQuestion(question.id)}
                                                        width="84" height="83" viewBox="0 0 84 83" fill="none">
                                                        <g filter="url(#filter0_d_173_553)">
                                                            <ellipse cx="41.9545" cy="36.3309" rx="25.0941" ry="25.3309" fill="#333333" />
                                                        </g>
                                                        <path d="M38.8735 44.5874L47.016 36.3308L38.8735 28.0742" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <defs>
                                                            <filter id="filter0_d_173_553" x="0.860352" y="0" width="82.1882" height="82.6616" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood fillOpacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="5" />
                                                                <feGaussianBlur stdDeviation="8" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_553" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_553" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                        <div className="answered__item--content">
                                            {question.id === selectedQuestion ? <div dangerouslySetInnerHTML={{ __html: question.content }} /> : ''}
                                        </div>
                                    </div>
                                )
                            })}

                    </div>
                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleQAPageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentPage === number ? 'bold_number' : ''}`}
                                    onClick={() => handleQAPageChange(number)}
                                    style={{ cursor: currentPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleQAPageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>

            {/* ----------------------- */}

            <Footer />
        </>
    );
}

export default Home;
