import React from "react";

function Wishes() {
    return (
        <div>
            <h2>祝福页面</h2>
            <p>This is the about page.</p>
        </div>
    );
}

export default Wishes;