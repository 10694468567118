import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import '../css/Onplay.scss';
import ImgOnplay1 from '../static/onplay-image-1.png';
import axios from 'axios';

function Onplay() {

    // const [videoUrl, setVideoUrl] = useState("https://websitevideos.oss-cn-beijing.aliyuncs.com/%E4%B8%93%E9%A2%98%E7%B3%BB%E5%88%97%E7%89%87/%E7%89%9B%E4%BA%BA%E7%9C%8B%E7%94%B5%E5%BD%B1/%E7%89%9B%E4%BA%BA%E7%9C%8B%E7%94%B5%E5%BD%B1%EF%BC%9A%E9%9D%A2%E5%AF%B9%E5%B7%A8%E4%BA%BA_-720p.mp4");
    const [videoUrl, setVideoUrl] = useState("");
    const [videoData, setVideoData] = useState({});

    const { id } = useParams();
    const videoRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [formattedTime, setFormattedTime] = useState("00:00/00:00");
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);


    const handleForward = () => {
        videoRef.current.currentTime += 10;
    };

    const handleBackward = () => {
        videoRef.current.currentTime -= 10;
    };

    const handlePlayOrPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMuteIconShow = () => {
        if (videoRef.current.muted) {
            videoRef.current.muted = false;
            setIsMuted(false);
        } else {
            videoRef.current.muted = true;
            setIsMuted(true);
        }
    }

    const handleFullscreen = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) { // Firefox
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { // IE/Edge
            videoRef.current.msRequestFullscreen();
        }
    };

    const handleProgressChange = (event) => {
        const currentTime = (duration * event.target.value) / 100;
        videoRef.current.currentTime = currentTime;
        setProgress(event.target.value);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        return `${hours > 0 ? hours.toString().padStart(2, '0') : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleTimeUpdate = () => {
        setProgress((videoRef.current.currentTime / duration) * 100);
        setFormattedTime(`${formatTime(videoRef.current.currentTime)}/${formatTime(videoRef.current.duration)}`);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    useEffect(() => {
        document.title = "播放 - 7G";
        document.querySelector('#root').className = 'onplay'

        const fetchVideoUrl = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/video/${id}`);
                const url = response.data.download_link;
                setVideoUrl(url);
                setVideoData(response.data);
            } catch (error) {
                console.error('Error fetching video URL:', error);
            }
        };

        fetchVideoUrl();
    }, [id]);

    return (
        <>
            <header className="header">
                <div className="container">
                    <Nav />
                </div>
            </header>

            <div className="video-container">
                {
                    videoUrl ? (
                        <video 
                            className="video"    
                            poster="https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg" 
                            ref={videoRef} 
                            src={videoUrl} 
                            onClick={handlePlayOrPause} 
                            onTimeUpdate={handleTimeUpdate}
                            onLoadedMetadata={handleLoadedMetadata}
                        > </video>
                    ) : (<h3>视频加载中...</h3>)
                }
                <div className="controls">
                    <div className="container">
                        <div className="conttrols_upside">
                            <input type="range" className="conttrols_upside--range" value={progress} min="0" max="100" onChange={handleProgressChange} />
                            <span className="conttrols_upside--time">{formattedTime}</span>
                        </div>
                        <div className="conttrols_downside">
                            <span className="conttrols_downside--left">
                                {!isPlaying ?
                                    <span className="conttrols_downside--play" onClick={handlePlayOrPause}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M38.6015 25.5097L22.1505 14.3542C21.5467 13.946 20.7453 13.8845 20.0836 14.2017C19.4165 14.5165 19 15.1559 19 15.847V38.1506C19 38.849 19.4165 39.486 20.0836 39.8008C20.3656 39.9336 20.6741 40 20.9852 40C21.3912 40 21.8025 39.8795 22.1505 39.6409L38.6015 28.4953C39.1208 28.1387 39.424 27.5878 39.424 27.0025C39.4266 26.4073 39.1156 25.8589 38.6015 25.5097Z" fill="white" />
                                        </svg>
                                    </span> :
                                    <span className="conttrols_downside--play" onClick={handlePlayOrPause}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <rect x="18" y="14" width="6" height="26" fill="white" />
                                            <rect x="30" y="14" width="6" height="26" fill="white" />
                                        </svg>
                                    </span>
                                }

                                <span className="conttrols_downside--forward" onClick={handleBackward}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                        <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                        <path d="M35.4265 18.5738C31.5028 14.6504 25.467 14.0572 20.8779 16.7981L20.957 16.3584C21.0064 16.0762 20.942 15.7859 20.7779 15.551C20.6139 15.3162 20.3634 15.1558 20.0814 15.1051C19.7995 15.0544 19.5089 15.1175 19.2732 15.2805C19.0376 15.4435 18.8761 15.6932 18.8242 15.9749L18.246 19.1887C18.2 19.445 18.2478 19.7093 18.3807 19.9332C18.5136 20.1572 18.7226 20.3258 18.9696 20.4082L21.7779 21.3445C21.9128 21.3895 22.0553 21.4075 22.1973 21.3974C22.3392 21.3874 22.4777 21.3495 22.605 21.2858C22.7323 21.2222 22.8458 21.1342 22.939 21.0267C23.0322 20.9192 23.1034 20.7945 23.1484 20.6595C23.1934 20.5245 23.2114 20.382 23.2013 20.2401C23.1913 20.0982 23.1534 19.9596 23.0898 19.8323C23.0261 19.7051 22.9381 19.5916 22.8306 19.4983C22.7232 19.4051 22.5984 19.3339 22.4634 19.2889L21.4925 18.9652C25.2853 16.3684 30.5355 16.7472 33.8945 20.1056C37.6956 23.9071 37.6956 30.0929 33.8945 33.8944C30.0922 37.6961 23.9075 37.6961 20.1052 33.8944C18.7854 32.57 17.8729 30.8951 17.4757 29.0681C17.0786 27.2411 17.2135 25.3385 17.8645 23.5858C17.9147 23.4524 17.9382 23.3103 17.9334 23.1679C17.9286 23.0254 17.8958 22.8852 17.8368 22.7554C17.7778 22.6257 17.6937 22.5088 17.5894 22.4116C17.4851 22.3144 17.3627 22.2387 17.2291 22.1889C17.0955 22.1391 16.9535 22.1161 16.811 22.1214C16.6685 22.1266 16.5285 22.1599 16.3989 22.2193C16.2693 22.2787 16.1527 22.3632 16.0559 22.4678C15.959 22.5724 15.8837 22.695 15.8343 22.8288C14.8845 25.3777 14.835 28.1749 15.6941 30.7559C16.5532 33.3368 18.2693 35.5463 20.5573 37.0175C22.8453 38.4886 25.5677 39.133 28.2724 38.8435C30.9772 38.5541 33.5016 37.3483 35.4265 35.4262C40.0724 30.7801 40.0724 23.22 35.4265 18.5738Z" fill="white" />
                                        <path d="M23.7499 32.4167C24.0372 32.4167 24.3128 32.3025 24.5159 32.0994C24.7191 31.8962 24.8332 31.6206 24.8332 31.3333V23.75C24.8332 23.5583 24.7823 23.37 24.6857 23.2044C24.5891 23.0388 24.4504 22.9018 24.2835 22.8073C24.1167 22.7129 23.9278 22.6644 23.7361 22.6668C23.5444 22.6693 23.3568 22.7225 23.1924 22.8211L20.4841 24.4461C20.2386 24.5944 20.062 24.834 19.9929 25.1123C19.9238 25.3906 19.9678 25.685 20.1154 25.9309C20.263 26.1768 20.5021 26.3541 20.7802 26.4241C21.0583 26.494 21.3528 26.4508 21.5991 26.3039L22.6666 25.6633V31.3333C22.6666 31.6206 22.7807 31.8962 22.9839 32.0994C23.1871 32.3025 23.4626 32.4167 23.7499 32.4167Z" fill="white" />
                                        <path d="M29.7076 32.4166C31.8336 32.4166 33.4993 30.2753 33.4993 27.5416C33.4993 24.8079 31.8337 22.6666 29.7076 22.6666C27.5816 22.6666 25.916 24.8079 25.916 27.5416C25.916 30.2754 27.5817 32.4166 29.7076 32.4166ZM29.7076 24.8333C30.4763 24.8333 31.3326 25.9454 31.3326 27.5416C31.3326 29.1379 30.4763 30.2499 29.7076 30.2499C28.939 30.2499 28.0826 29.1378 28.0826 27.5416C28.0826 25.9454 28.9392 24.8333 29.7076 24.8333Z" fill="white" />
                                    </svg>
                                </span>
                                <span className="conttrols_downside--backward" onClick={handleForward}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                        <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                        <path d="M39.1785 23.4463C39.1258 23.2989 39.0443 23.1633 38.9388 23.0476C38.8333 22.9319 38.7058 22.8384 38.5638 22.7723C38.4219 22.7063 38.2682 22.6691 38.1117 22.663C37.9552 22.6569 37.7991 22.6819 37.6524 22.7366C37.5057 22.7913 37.3713 22.8746 37.257 22.9817C37.1428 23.0888 37.051 23.2175 36.9869 23.3604C36.9228 23.5033 36.8877 23.6575 36.8837 23.814C36.8797 23.9706 36.9069 24.1264 36.9636 24.2723C37.6739 26.1848 37.8211 28.2608 37.3878 30.2543C36.9545 32.2479 35.9588 34.0755 34.5188 35.5205C30.3702 39.6681 23.6209 39.6681 19.4735 35.5205C15.3261 31.3729 15.3261 24.6229 19.4735 20.475C23.131 16.8171 28.8461 16.3976 33.0006 19.2319L31.9456 19.5836C31.7983 19.6327 31.6622 19.7104 31.5449 19.8121C31.4276 19.9138 31.3316 20.0377 31.2622 20.1765C31.1928 20.3154 31.1514 20.4666 31.1404 20.6214C31.1294 20.7763 31.1491 20.9318 31.1982 21.0791C31.2473 21.2263 31.3249 21.3625 31.4266 21.4798C31.5284 21.597 31.6522 21.6931 31.7911 21.7625C31.9299 21.8319 32.0811 21.8733 32.236 21.8843C32.3908 21.8953 32.5463 21.8756 32.6936 21.8265L35.7576 20.8049C36.0271 20.715 36.2552 20.531 36.4002 20.2866C36.5452 20.0423 36.5973 19.7539 36.5471 19.4742L35.9164 15.9678C35.8597 15.6603 35.6835 15.3879 35.4264 15.21C35.1693 15.0321 34.8523 14.9633 34.5446 15.0187C34.2369 15.074 33.9637 15.2489 33.7847 15.5052C33.6056 15.7615 33.5354 16.0783 33.5893 16.3862L33.6743 16.8586C28.6799 13.8729 22.0864 14.5179 17.8018 18.8035C12.7327 23.8731 12.7327 32.1223 17.8018 37.192C19.9014 39.2913 22.6561 40.6088 25.6082 40.9257C28.5602 41.2426 31.5317 40.5397 34.029 38.9339C36.5263 37.3281 38.399 34.916 39.3357 32.0986C40.2725 29.2811 40.217 26.2279 39.1785 23.4463Z" fill="white" />
                                        <path d="M23.4505 33.9081C23.764 33.9081 24.0647 33.7836 24.2863 33.5619C24.508 33.3402 24.6325 33.0395 24.6325 32.726V24.4515C24.6325 24.2423 24.5769 24.0369 24.4716 23.8562C24.3662 23.6755 24.2148 23.526 24.0327 23.4229C23.8507 23.3199 23.6446 23.267 23.4354 23.2696C23.2263 23.2723 23.0216 23.3303 22.8422 23.438L19.8872 25.2111C19.6194 25.3729 19.4266 25.6343 19.3512 25.938C19.2758 26.2417 19.3239 26.5629 19.485 26.8312C19.646 27.0995 19.9068 27.293 20.2103 27.3693C20.5137 27.4456 20.8351 27.3984 21.1038 27.2382L22.2685 26.5392V32.726C22.2685 33.0395 22.3931 33.3402 22.6147 33.5619C22.8364 33.7836 23.137 33.9081 23.4505 33.9081Z" fill="white" />
                                        <path d="M29.9515 23.2695C27.6319 23.2695 25.8145 25.6059 25.8145 28.5888C25.8145 31.5717 27.6318 33.9081 29.9515 33.9081C32.2711 33.9081 34.0885 31.5717 34.0885 28.5888C34.0885 25.6059 32.2712 23.2695 29.9515 23.2695ZM29.9515 31.544C29.1129 31.544 28.1784 30.3305 28.1784 28.5888C28.1784 26.8471 29.1129 25.6336 29.9515 25.6336C30.79 25.6336 31.7245 26.847 31.7245 28.5888C31.7245 30.3305 30.7901 31.544 29.9515 31.544Z" fill="white" />
                                    </svg>
                                </span>
                            </span>
                            <span className="conttrols_downside--right">
                                {isMuted ?
                                    <span className="conttrols_downside--mute" onClick={handleMuteIconShow}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M34.4271 14.9327C34.4271 14.5799 34.2228 14.2457 33.9071 14.0972C33.5728 13.9301 33.2013 13.9858 32.9228 14.2086L24.6953 20.7632L34.4271 30.493V14.9327Z" fill="white" />
                                            <path d="M39.7284 38.4143L15.5847 14.2754C15.2225 13.9134 14.6338 13.9134 14.2716 14.2754C13.9095 14.6375 13.9095 15.2261 14.2716 15.5882L20.1144 21.4317H19.5702C18.9945 21.4317 18.4931 21.6916 18.1588 22.1001C17.8802 22.4158 17.713 22.8429 17.713 23.2885V30.7158C17.713 31.7371 18.5488 32.5727 19.5702 32.5727H23.879L32.9236 39.7958C33.0907 39.9257 33.295 40 33.4993 40C33.6293 40 33.7779 39.9629 33.9079 39.9072C34.2236 39.7586 34.4279 39.4244 34.4279 39.0716V35.7423L38.4135 39.7271C38.5955 39.909 38.8332 40 39.0709 40C39.3087 40 39.5464 39.909 39.7284 39.7289C40.0905 39.365 40.0905 38.7782 39.7284 38.4143Z" fill="white" />
                                        </svg>
                                    </span> :
                                    <span className="conttrols_downside--volume" onClick={handleMuteIconShow}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M24 16L16 22H10V32H16L24 38V16Z" fill="white" />
                                            <path d="M32.5 21.5C34.5 23.5 34.5 30.5 32.5 32.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                            <path d="M36.5 17.5C40.5 21.5 40.5 32.5 36.5 36.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </span>
                                }
                                <span className="conttrols_downside--fullscreen" onClick={handleFullscreen}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                        <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                        <path d="M32.3867 15.1556L34.3978 17.3222L31.2376 20.5C30.663 21.0778 30.663 21.9444 31.2376 22.5222C31.8122 23.1 32.674 23.1 33.2486 22.5222L36.4088 19.3444L38.5635 21.3667C39.1381 21.9444 39.8564 21.5111 39.8564 20.9333V14.7222C39.8564 14.2889 39.5691 14 39.1381 14H32.9613C32.3867 14 31.9558 14.7222 32.3867 15.1556ZM15.1492 21.3667L17.1602 19.3444L20.3204 22.5222C20.895 23.1 21.7569 23.1 22.3315 22.5222C22.9061 21.9444 22.9061 21.0778 22.3315 20.5L19.3149 17.3222L21.326 15.1556C21.9006 14.7222 21.4696 14 20.895 14H14.7182C14.2873 14 14 14.2889 14 14.7222V20.9333C14 21.5111 14.7182 21.9444 15.1492 21.3667ZM21.326 38.7L19.1713 36.6778L22.3315 33.5C22.9061 32.9222 22.9061 32.0556 22.3315 31.4778C21.7569 30.9 20.895 30.9 20.3204 31.4778L17.1602 34.6556L15.0055 32.6333C14.7182 32.0556 14 32.4889 14 33.0667V39.2778C14 39.7111 14.2873 40 14.7182 40H20.895C21.4696 40 21.9006 39.2778 21.326 38.7ZM38.7072 32.4889L36.5525 34.5111L33.3923 31.3333C32.8177 30.7556 31.9558 30.7556 31.3812 31.3333C30.8066 31.9111 30.8066 32.7778 31.3812 33.3556L34.5414 36.5333L32.5304 38.7C32.0994 39.1333 32.3867 40 33.105 40H39.2818C39.7127 40 40 39.7111 40 39.2778V33.0667C39.8564 32.4889 39.1381 32.0556 38.7072 32.4889Z" fill="white" />
                                    </svg></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="video_footer">
                    <div className="container">
                        <span className="video_footer--left">
                            <a href="#" className="download">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                    <path d="M11.1222 18.6709C11.2375 18.7861 11.3744 18.8776 11.525 18.94C11.6756 19.0024 11.837 19.0345 12 19.0345C12.1631 19.0345 12.3245 19.0024 12.4751 18.94C12.6257 18.8776 12.7626 18.7861 12.8779 18.6709L17.5594 13.9894C17.7922 13.7566 17.9229 13.4408 17.9229 13.1116C17.9229 12.7823 17.7922 12.4666 17.5594 12.2338C17.3266 12.001 17.0108 11.8702 16.6816 11.8702C16.3523 11.8702 16.0366 12.001 15.8038 12.2338L13.2414 14.7962V1.24138C13.2414 0.912145 13.1106 0.596395 12.8778 0.363592C12.645 0.130788 12.3293 0 12 0C11.6708 0 11.3551 0.130788 11.1223 0.363592C10.8895 0.596395 10.7587 0.912145 10.7587 1.24138V14.7962L8.19631 12.2338C7.9635 12.001 7.64775 11.8702 7.31852 11.8702C6.98929 11.8702 6.67354 12.001 6.44074 12.2338C6.20793 12.4666 6.07715 12.7823 6.07715 13.1116C6.07715 13.4408 6.20793 13.7566 6.44074 13.9894L11.1222 18.6709Z" fill="white" />
                                    <path d="M22.7586 10.7586C22.4294 10.7586 22.1136 10.8894 21.8808 11.1222C21.648 11.355 21.5172 11.6708 21.5172 12V21.5173H2.48276V12C2.48276 11.6708 2.35197 11.355 2.11917 11.1222C1.88636 10.8894 1.57061 10.7586 1.24138 10.7586C0.912145 10.7586 0.596395 10.8894 0.363592 11.1222C0.130788 11.355 0 11.6708 0 12V21.9311C0 22.4798 0.21798 23.006 0.605986 23.394C0.993992 23.782 1.52024 24 2.06897 24H21.931C22.4798 24 23.006 23.782 23.394 23.394C23.782 23.006 24 22.4798 24 21.9311V12C24 11.6708 23.8692 11.355 23.6364 11.1222C23.4036 10.8894 23.0879 10.7586 22.7586 10.7586Z" fill="white" />
                                </svg>{' '}Download
                            </a>
                            <a href="#" className="share">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                    <path d="M8.19577 6.80072L10.7581 4.23837V17.7931C10.7581 18.1223 10.8889 18.4381 11.1217 18.6709C11.3545 18.9037 11.6703 19.0345 11.9995 19.0345C12.3287 19.0345 12.6445 18.9037 12.8773 18.6709C13.1101 18.4381 13.2409 18.1223 13.2409 17.7931V4.23837L15.8033 6.80072C15.9184 6.91651 16.0553 7.00844 16.206 7.07124C16.3568 7.13404 16.5184 7.16648 16.6817 7.1667C16.845 7.16692 17.0068 7.13492 17.1577 7.07253C17.3086 7.01014 17.4457 6.91859 17.5612 6.80311C17.6767 6.68764 17.7682 6.55052 17.8306 6.3996C17.893 6.24868 17.925 6.08694 17.9248 5.92364C17.9246 5.76033 17.8921 5.59868 17.8293 5.44793C17.7665 5.29719 17.6746 5.16032 17.5588 5.04516L12.8773 0.363613C12.762 0.248334 12.6252 0.156889 12.4746 0.0945003C12.324 0.0321113 12.1625 0 11.9995 0C11.8365 0 11.6751 0.0321113 11.5244 0.0945003C11.3738 0.156889 11.237 0.248334 11.1217 0.363613L6.4402 5.04516C6.32441 5.16032 6.23249 5.29719 6.16968 5.44793C6.10688 5.59868 6.07444 5.76033 6.07422 5.92364C6.074 6.08694 6.106 6.24868 6.16839 6.3996C6.23078 6.55052 6.32234 6.68764 6.43781 6.80311C6.55328 6.91859 6.69041 7.01014 6.84132 7.07253C6.99224 7.13492 7.15398 7.16692 7.31729 7.1667C7.48059 7.16648 7.64225 7.13404 7.79299 7.07124C7.94374 7.00844 8.08061 6.91651 8.19577 6.80072Z" fill="white" />
                                    <path d="M22.7586 10.7586C22.4294 10.7586 22.1136 10.8894 21.8808 11.1222C21.648 11.355 21.5172 11.6708 21.5172 12V21.5172H2.48276V12C2.48276 11.6708 2.35197 11.355 2.11917 11.1222C1.88636 10.8894 1.57061 10.7586 1.24138 10.7586C0.912145 10.7586 0.596395 10.8894 0.363592 11.1222C0.130788 11.355 0 11.6708 0 12V21.931C0 22.4798 0.21798 23.006 0.605986 23.394C0.993992 23.782 1.52024 24 2.06897 24H21.931C22.4798 24 23.006 23.782 23.394 23.394C23.782 23.006 24 22.4798 24 21.931V12C24 11.6708 23.8692 11.355 23.6364 11.1222C23.4036 10.8894 23.0879 10.7586 22.7586 10.7586Z" fill="white" />
                                </svg>{' '}Share
                            </a>
                        </span>
                        <span className="video_footer--right"> </span>
                    </div>
                </div>
            </div>

            <main className="video-intro">
                <div className="container">
                    {
                        videoData && (
                            <div className="intro-upside">
                                <h1 className="title">{videoData.video_name}</h1>
                                <h2 className="subtitle">{videoData.title}</h2>
                                <p className="synopsis">
                                    {videoData.summary}
                                </p>
                            </div>

                        )
                    }
                    {/* <div className="intro-downside" >
                        <div className="reviews">
                            <h3 className="reviews-title">Reviews</h3>
                            <div className="review">
                                <div className="review-row">
                                    <h4 className="reviewer-name">Tania Shawn</h4>
                                    <span className="review-rating"><svg xmlns="http://www.w3.org/2000/svg" width="123" height="21" viewBox="0 0 123 21" fill="none">
                                        <path d="M21.4434 8.62554L16.7593 13.4232L17.8604 20.2134C17.9574 20.7553 17.3693 21.152 16.8991 20.8951L11.1401 17.7053V0.0263672C11.3848 0.0263672 11.6295 0.139974 11.7344 0.375927L14.627 6.53692L21.0676 7.51568C21.606 7.61181 21.8043 8.24539 21.4434 8.62554Z" fill="#DB5443" />
                                        <path d="M11.1406 0.0263672V17.7053L5.38163 20.8951C4.91934 21.1546 4.32246 20.7614 4.42034 20.2134L5.52145 13.4232L0.837354 8.62553C0.476433 8.24539 0.673935 7.61181 1.21313 7.51568L7.65377 6.53692L10.5464 0.375927C10.6512 0.139974 10.8959 0.0263672 11.1406 0.0263672Z" fill="#DB5443" />
                                        <path d="M46.7852 8.62554L42.1011 13.4232L43.2022 20.2134C43.2992 20.7553 42.7111 21.152 42.2409 20.8951L36.4819 17.7053V0.0263672C36.7266 0.0263672 36.9713 0.139974 37.0762 0.375927L39.9688 6.53692L46.4094 7.51568C46.9478 7.61181 47.1461 8.24539 46.7852 8.62554Z" fill="#DB5443" />
                                        <path d="M36.4824 0.0262451V17.7052L30.7234 20.895C30.2611 21.1545 29.6643 20.7612 29.7621 20.2133L30.8633 13.4231L26.1792 8.62541C25.8182 8.24527 26.0157 7.61169 26.5549 7.51556L32.9956 6.53679L35.8882 0.375805C35.993 0.139852 36.2377 0.0262451 36.4824 0.0262451Z" fill="#DB5443" />
                                        <path d="M72.129 8.59917L67.4449 13.3969L68.546 20.1871C68.643 20.7289 68.0548 21.1256 67.5847 20.8687L61.8257 17.679V0C62.0704 0 62.3151 0.113607 62.4199 0.34956L65.3125 6.51055L71.7532 7.48932C72.2915 7.58545 72.4899 8.21902 72.129 8.59917Z" fill="#DB5443" />
                                        <path d="M61.8257 0V17.679L56.0667 20.8687C55.6044 21.1283 55.0075 20.735 55.1054 20.1871L56.2065 13.3969L51.5224 8.59917C51.1615 8.21902 51.359 7.58544 51.8982 7.48932L58.3388 6.51055L61.2314 0.34956C61.3363 0.113607 61.581 0 61.8257 0Z" fill="#DB5443" />
                                        <path d="M97.4717 8.59917L92.7876 13.3969L93.8887 20.1871C93.9857 20.7289 93.3976 21.1256 92.9275 20.8687L87.1685 17.679V0C87.4131 0 87.6578 0.113607 87.7627 0.34956L90.6553 6.51055L97.096 7.48932C97.6343 7.58545 97.8326 8.21902 97.4717 8.59917Z" fill="#DB5443" />
                                        <path d="M87.1689 0V17.679L81.4099 20.8687C80.9477 21.1283 80.3508 20.735 80.4487 20.1871L81.5498 13.3969L76.8657 8.59917C76.5048 8.21902 76.7023 7.58544 77.2415 7.48932L83.6821 6.51055L86.5747 0.34956C86.6796 0.113607 86.9243 0 87.1689 0Z" fill="#DB5443" />
                                        <path d="M122.815 8.59917L118.13 13.3969L119.232 20.1871C119.329 20.7289 118.74 21.1256 118.27 20.8687L112.511 17.679V0C112.756 0 113.001 0.113607 113.105 0.34956L115.998 6.51055L122.439 7.48932C122.977 7.58545 123.175 8.21902 122.815 8.59917Z" fill="#DB5443" />
                                        <path d="M112.512 0V17.679L106.753 20.8687C106.29 21.1283 105.694 20.735 105.791 20.1871L106.893 13.3969L102.208 8.59917C101.848 8.21902 102.045 7.58544 102.584 7.48932L109.025 6.51055L111.917 0.34956C112.022 0.113607 112.267 0 112.512 0Z" fill="#DB5443" />
                                    </svg></span>
                                </div>
                                <p className="review-text">
                                    The efficiency service is the most awesome in a place, and the performances are lovely. I strongly
                                    refer to a magical drama to brighten your some days. “Our Memory in Taiwan” is the perfect choice.
                                </p>
                            </div>
                            <div className="review">
                                <div className="review-row">
                                    <h4 className="reviewer-name">Karina Marlow</h4>
                                    <span className="review-rating"><svg xmlns="http://www.w3.org/2000/svg" width="123" height="21" viewBox="0 0 123 21" fill="none">
                                        <path d="M21.4434 8.62554L16.7593 13.4232L17.8604 20.2134C17.9574 20.7553 17.3693 21.152 16.8991 20.8951L11.1401 17.7053V0.0263672C11.3848 0.0263672 11.6295 0.139974 11.7344 0.375927L14.627 6.53692L21.0676 7.51568C21.606 7.61181 21.8043 8.24539 21.4434 8.62554Z" fill="#DB5443" />
                                        <path d="M11.1406 0.0263672V17.7053L5.38163 20.8951C4.91934 21.1546 4.32246 20.7614 4.42034 20.2134L5.52145 13.4232L0.837354 8.62553C0.476433 8.24539 0.673935 7.61181 1.21313 7.51568L7.65377 6.53692L10.5464 0.375927C10.6512 0.139974 10.8959 0.0263672 11.1406 0.0263672Z" fill="#DB5443" />
                                        <path d="M46.7852 8.62554L42.1011 13.4232L43.2022 20.2134C43.2992 20.7553 42.7111 21.152 42.2409 20.8951L36.4819 17.7053V0.0263672C36.7266 0.0263672 36.9713 0.139974 37.0762 0.375927L39.9688 6.53692L46.4094 7.51568C46.9478 7.61181 47.1461 8.24539 46.7852 8.62554Z" fill="#DB5443" />
                                        <path d="M36.4824 0.0262451V17.7052L30.7234 20.895C30.2611 21.1545 29.6643 20.7612 29.7621 20.2133L30.8633 13.4231L26.1792 8.62541C25.8182 8.24527 26.0157 7.61169 26.5549 7.51556L32.9956 6.53679L35.8882 0.375805C35.993 0.139852 36.2377 0.0262451 36.4824 0.0262451Z" fill="#DB5443" />
                                        <path d="M72.129 8.59917L67.4449 13.3969L68.546 20.1871C68.643 20.7289 68.0548 21.1256 67.5847 20.8687L61.8257 17.679V0C62.0704 0 62.3151 0.113607 62.4199 0.34956L65.3125 6.51055L71.7532 7.48932C72.2915 7.58545 72.4899 8.21902 72.129 8.59917Z" fill="#DB5443" />
                                        <path d="M61.8257 0V17.679L56.0667 20.8687C55.6044 21.1283 55.0075 20.735 55.1054 20.1871L56.2065 13.3969L51.5224 8.59917C51.1615 8.21902 51.359 7.58544 51.8982 7.48932L58.3388 6.51055L61.2314 0.34956C61.3363 0.113607 61.581 0 61.8257 0Z" fill="#DB5443" />
                                        <path d="M97.4717 8.59917L92.7876 13.3969L93.8887 20.1871C93.9857 20.7289 93.3976 21.1256 92.9275 20.8687L87.1685 17.679V0C87.4131 0 87.6578 0.113607 87.7627 0.34956L90.6553 6.51055L97.096 7.48932C97.6343 7.58545 97.8326 8.21902 97.4717 8.59917Z" fill="#DB5443" />
                                        <path d="M87.1689 0V17.679L81.4099 20.8687C80.9477 21.1283 80.3508 20.735 80.4487 20.1871L81.5498 13.3969L76.8657 8.59917C76.5048 8.21902 76.7023 7.58544 77.2415 7.48932L83.6821 6.51055L86.5747 0.34956C86.6796 0.113607 86.9243 0 87.1689 0Z" fill="#DB5443" />
                                        <path d="M122.815 8.59917L118.13 13.3969L119.232 20.1871C119.329 20.7289 118.74 21.1256 118.27 20.8687L112.511 17.679V0C112.756 0 113.001 0.113607 113.105 0.34956L115.998 6.51055L122.439 7.48932C122.977 7.58545 123.175 8.21902 122.815 8.59917Z" fill="#DB5443" />
                                        <path d="M112.512 0V17.679L106.753 20.8687C106.29 21.1283 105.694 20.735 105.791 20.1871L106.893 13.3969L102.208 8.59917C101.848 8.21902 102.045 7.58544 102.584 7.48932L109.025 6.51055L111.917 0.34956C112.022 0.113607 112.267 0 112.512 0Z" fill="#DB5443" />
                                    </svg></span>
                                </div>
                                <p className="review-text">
                                    The experience basically captures themes of self-discovery, friendship, and the pursuit of dreams.
                                    The cast delivers wonderful performances, bringing the characters to life with their endearing portrayals.
                                </p>
                            </div>
                        </div>
                        <div className="leave-review">
                            <h3 className="leave-review-title">Leave Your Review</h3>
                            <form className="review-form">
                                <div className="form-row">
                                    <input type="text" name="name" placeholder="Your name" className="input-name" />
                                    <span className="input-rating">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="14" viewBox="0 0 96 14" fill="none">
                                            <path d="M12.7011 5.66312L9.84287 8.59067L10.5148 12.734C10.574 13.0647 10.2151 13.3068 9.92819 13.15L6.41406 11.2036V0.415924C6.56337 0.415924 6.71268 0.485247 6.77667 0.629225L8.54174 4.38865L12.4718 4.98589C12.8003 5.04455 12.9213 5.43116 12.7011 5.66312Z" fill="#DB5443" />
                                            <path d="M6.41388 0.415924V11.2036L2.89975 13.15C2.61766 13.3083 2.25345 13.0684 2.31317 12.734L2.98507 8.59067L0.126838 5.66312C-0.0933953 5.43116 0.0271196 5.04455 0.356136 4.98589L4.2862 4.38865L6.05127 0.629225C6.11526 0.485247 6.26457 0.415924 6.41388 0.415924Z" fill="#DB5443" />
                                            <path d="M33.497 5.66312L30.6388 8.59067L31.3107 12.734C31.3699 13.0647 31.011 13.3068 30.7241 13.15L27.21 11.2036V0.415924C27.3593 0.415924 27.5086 0.485247 27.5726 0.629225L29.3376 4.38865L33.2677 4.98589C33.5962 5.04455 33.7172 5.43116 33.497 5.66312Z" fill="#DB5443" />
                                            <path d="M27.2103 0.415955V11.2036L23.6961 13.15C23.414 13.3084 23.0498 13.0684 23.1096 12.7341L23.7815 8.5907L20.9232 5.66315C20.703 5.43119 20.8235 5.04458 21.1525 4.98592L25.0826 4.38868L26.8477 0.629255C26.9116 0.485277 27.061 0.415955 27.2103 0.415955Z" fill="#DB5443" />
                                            <path d="M54.2939 5.64719L51.4356 8.57474L52.1075 12.7181C52.1667 13.0487 51.8079 13.2908 51.521 13.134L48.0068 11.1877V0.399994C48.1561 0.399994 48.3055 0.469317 48.3694 0.613295L50.1345 4.37272L54.0646 4.96996C54.3931 5.02862 54.5141 5.41523 54.2939 5.64719Z" fill="#DB5443" />
                                            <path d="M48.0067 0.399994V11.1877L44.4925 13.134C44.2104 13.2924 43.8462 13.0525 43.9059 12.7181L44.5778 8.57474L41.7196 5.64719C41.4994 5.41523 41.6199 5.02862 41.9489 4.96996L45.879 4.37272L47.644 0.613295C47.708 0.469317 47.8573 0.399994 48.0067 0.399994Z" fill="#DB5443" />
                                            <path d="M75.0912 5.64719L72.233 8.57474L72.9049 12.7181C72.9641 13.0487 72.6052 13.2908 72.3183 13.134L68.8042 11.1877V0.399994C68.9535 0.399994 69.1028 0.469317 69.1668 0.613295L70.9319 4.37272L74.8619 4.96996C75.1904 5.02862 75.3115 5.41523 75.0912 5.64719Z" fill="#ECEFF1" />
                                            <path d="M68.8045 0.399994V11.1877L65.2904 13.134C65.0083 13.2924 64.6441 13.0525 64.7038 12.7181L65.3757 8.57474L62.5175 5.64719C62.2972 5.41523 62.4177 5.02862 62.7468 4.96996L66.6768 4.37272L68.4419 0.613295C68.5059 0.469317 68.6552 0.399994 68.8045 0.399994Z" fill="#ECEFF1" />
                                            <path d="M95.8876 5.64719L93.0294 8.57474L93.7013 12.7181C93.7605 13.0487 93.4016 13.2908 93.1147 13.134L89.6006 11.1877V0.399994C89.7499 0.399994 89.8992 0.469317 89.9632 0.613295L91.7283 4.37272L95.6583 4.96996C95.9868 5.02862 96.1079 5.41523 95.8876 5.64719Z" fill="#ECEFF1" />
                                            <path d="M89.6009 0.399994V11.1877L86.0868 13.134C85.8047 13.2924 85.4405 13.0525 85.5002 12.7181L86.1721 8.57474L83.3138 5.64719C83.0936 5.41523 83.2141 5.02862 83.5431 4.96996L87.4732 4.37272L89.2383 0.613295C89.3023 0.469317 89.4516 0.399994 89.6009 0.399994Z" fill="#ECEFF1" />
                                        </svg>
                                    </span>
                                </div>
                                <textarea name="review" placeholder="Write Your Review" className="input-review"></textarea>
                                <a type="submit" className="submit-button">Submit</a>
                            </form>
                        </div>
                    </div> */}

                </div>
            </main>
            <Footer />
        </>
    );
}

export default Onplay;