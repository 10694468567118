import React from 'react';

function Series() {
    return (
        <div>
            <h2>系列页面</h2>
            <p>This is the about page.</p>
        </div>
    );
}

export default Series;